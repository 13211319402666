import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "../components/text"

const NotFoundPage = () => (
  <Layout heading="Not found">
    <SEO title="404: Not found" />
    <Text>
        <p>Sorry, we can’t find the thing you asked for :(</p>
    </Text>
  </Layout>
)

export default NotFoundPage
